import { Injectable, inject } from '@angular/core';
import { ApiService } from '@mca/shared/util';
import { SettlementAttributes, SettlementDurationInfo, SettlementFormData, SettlementRequestParameters } from '../entities/settlement';
import { Transactions, TransferType } from '../entities/trans-rec';
import {
  httpMcaActivateContractFee,
  httpMcaActivateDeposit,
  httpMcaComUsersTrans,
  httpMcaFailedUnproccessedTrans,
  httpMcaFinalizeCommissions,
  httpMcaGenerateCommissions,
  httpMcaGenerateMCACommission,
  httpMcaSyndTrans,
  httpMcaTransContractFee,
  httpMcaTransHold,
  httpMcaUnwindCommissions,
  httpRestructuring,
  httpActivateFeeToInvestor,
  httpTransactionList,
  httpTransactionsBulk,
  httpMcaNextPayment,
  httpMcaRequestPayments,
  httpMcaGeneratePayments,
  httpSettlementRequestParameters,
  httpSettlementRequest,
  httpSettlementDurationInfo,
  httpSettlementAttributes,
  httpQuickBooks,
  httpTransactionNonACHSearch,
  httpQBManualTransactions,
} from './transaction-http-points';
import { map } from 'rxjs';

export interface GeneratePaymentsParams {
  startDate: string;
  frequency: number;
  payment: number;
}

export interface NonACHSearch {
  quickBookName: string;
  description: string;
  grossAmount: number;
  netAmount: number;
  feeAmount: number;
}

@Injectable({
  providedIn: 'root',
})
export class McaTransactionService {
  private apiService = inject(ApiService);

  getTransactionList(doc: any) {
    return this.apiService.post<Transactions[]>(httpTransactionList(), {
      ...doc,
      includeCanceled: !!doc.includeCanceled,
    });
  }

  createTransactionsBulk(records: Transactions[]) {
    return this.apiService.put(httpTransactionsBulk(), records);
  }

  getFailedUnproccessedTrans(
    id: number,
    params: {
      transferType?: TransferType;
    },
  ) {
    return this.apiService.get(httpMcaFailedUnproccessedTrans(id), { params });
  }

  getSyndicatorTransactions(
    mcaId: number,
    params: {
      transferType?: TransferType;
      transTypes: number[];
    },
  ) {
    return this.apiService.get(httpMcaSyndTrans(mcaId), { params });
  }

  getCommUsersTransactions(
    mcaId: number,
    params: {
      transferType?: TransferType;
    },
  ) {
    return this.apiService.get<any[]>(httpMcaComUsersTrans(mcaId), { params });
  }

  activateDeposit(mcaId: number, transId: number, depType: string, status: number, venue: string, contractFee: number) {
    return this.apiService.post(httpMcaActivateDeposit(mcaId, depType, transId, status, venue), { contractFee });
  }

  activateContractFee(mcaId: number, transId: number) {
    return this.apiService.post(httpMcaActivateContractFee(mcaId, transId));
  }

  // unused (was on unwind button on ISO sets)
  unwindCommissions(mcaId: number) {
    return this.apiService.post(httpMcaUnwindCommissions(mcaId));
  }

  // unused (was on apply button on ISO sets)
  generateCommissions(mcaId: number) {
    return this.apiService.post(httpMcaGenerateCommissions(mcaId));
  }

  getTransactionContracFee(mcaId: number, transId: number) {
    return this.apiService.get<number>(httpMcaTransContractFee(mcaId, transId));
  }

  finalizeCommissions(mcaId: number) {
    return this.apiService.post(httpMcaFinalizeCommissions(mcaId));
  }

  generateMCACommission(mcaId: number, generateCommissionAmt: number, comment: string) {
    return this.apiService.post(httpMcaGenerateMCACommission(mcaId), { amount: generateCommissionAmt, comment });
  }

  hold(mcaId: number) {
    return this.apiService.post(httpMcaTransHold(mcaId));
  }

  getRestructuringInfo(mcaId: number) {
    return this.apiService.get(httpRestructuring(mcaId));
  }

  restructuring(mcaId: number, params: any) {
    return this.apiService.post(httpRestructuring(mcaId), params);
  }

  activateFeeToInvestor(doc: any) {
    return this.apiService.post(httpActivateFeeToInvestor(), doc);
  }

  getNextPayment(mcaid: number) {
    return this.apiService.get(httpMcaNextPayment(mcaid));
  }

  updateNextPayment(
    mcaid: number,
    payment: {
      amount: number;
    },
  ) {
    return this.apiService.put(httpMcaNextPayment(mcaid), payment);
  }

  requestPayments(mcaid: number, requestInfo: any) {
    return this.apiService.post(httpMcaRequestPayments(mcaid), requestInfo);
  }

  generatePayments(mcaid: number, params?: GeneratePaymentsParams) {
    return this.apiService.post(httpMcaGeneratePayments(mcaid), params);
  }

  getSettlementRequestParameters(mcaid: number) {
    return this.apiService.get<SettlementRequestParameters>(httpSettlementRequestParameters(mcaid));
  }

  getSettlementDurationInfo(params: { frequency: number; payment: number; balance: number }) {
    return this.apiService
      .post<SettlementDurationInfo>(httpSettlementDurationInfo(), params)
      .pipe(map(data => ({ ...data, last_payment_date: new Date(data.last_payment_date) })));
  }

  getSettlementAttributes(mcaid: number) {
    return this.apiService.get<SettlementAttributes>(httpSettlementAttributes(mcaid));
  }

  requestSettlement(mcaid: number, params: SettlementFormData) {
    return this.apiService.post(httpSettlementRequest(mcaid), params);
  }

  getQuickBooksData(
    module: string,
    params: {
      startDate: string;
      endDate: string;
      venue: number;
    },
  ) {
    return this.apiService.get(httpQuickBooks(module), { params });
  }

  getNonACHPayments(params: { startDate: string; endDate: string; venue: number; paymentMethod: string; defaultOnly: boolean }) {
    return this.apiService.get<NonACHSearch[]>(httpTransactionNonACHSearch(), { params });
  }

  getQBManualTransactions(mcaid: number) {
    return this.apiService.get(httpQBManualTransactions(mcaid));
  }
}
