import { Injectable, inject, signal } from '@angular/core';
import { ApiService } from '@mca/shared/util';
import { first, shareReplay, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BusinessEventModel } from '@mca/shared/domain';
import { dateAsYYYYMMDD } from '@mca/shared/util';
import { TransactionRef, TransConstants } from '../entities/trans-rec';
import { TransAssignmentType } from '@mca/shared/domain';
import { transDefenitions, TransStatusAcro, TransSubTypeAcro, TransTypeAcro } from '../entities/trans-const';
import {
  httpCommissionActivateCandidates,
  httpTransaction,
  httpTransactionItem,
  httpTransactionsACHSearch,
  httpTransactionsAllocDef,
  httpTransactionsAllocNonAchPmnt,
  httpTransactionsConstants,
  httpTransactionsReferences,
  httpTransactionsReleaseBulk,
  receivablesAch,
  httpBETransactionsEvents,
  httpTransactionChangelog,
  httpCommissionInvoiceSummary,
  httpCreateCommissionInvoice,
  httpTransactionAggregation,
  httpTransactionsMerge,
  httpTransactionHold,
  httpTransactionAssign,
  httpTransactionUnassign,
  httpTransactionCollectorUnallocated,
  httpTransactionCollectorAllocate,
  httpTransactionTransferAccount,
  httpTransactionVoid,
  httpTransactionsSplitBucket,
  httpActivateBPWithdrawalTransfer,
  httpDefaultFees,
  httpActivateWithdrawalTransaction,
} from '../infrastructure/transaction-http-points';
import { ReceivableAch } from '../entities/receivable-ach';
import { ACHRecord } from '../entities/ach-record';
import { SelectItem } from 'primeng/api';

export interface AggregationFilters {
  dealvenue: number | undefined;
  trans_type: number[];
  parent_trans_type: number[];
  trans_subtype: number[];
  status: number[];
  start_date: Date;
  end_date: Date;
  freq: string | undefined;
  userid: number | undefined;
  user_roles: number[];
}

export interface AggregationAction {
  amount: number;
  datefreq: string;
  dealvenue: number;
}

interface TransactionReferenceParams {
  fromBucket: number;
  toBucket?: number;
  transactionIds: number[];
  type: 'source' | 'destination' | 'related';
}

export interface CommissionInvoiceAction {
  invoiceno: string;
  amount: number;
  venue: string;
  venueid: number;
}
export interface CreateCommissionInvoice {
  userid: number | null;
  startDate: string;
  endDate: string;
  invoices: {
    venueid: number;
    invoiceno: string;
  }[];
}

export interface McaAllocationList {
  mcaid: number;
  amount: number;
  fee: number;
}

export interface CollectorPayment {
  amount: number;
  dealvenue: number;
  id: number;
  mcaid: number;
}

export interface TransactionTransferAccount {
  id: number;
  mcaid: number;
  effectivedate: string;
  dealvenue: number;
  ammount: number;
  transtype: number;
  transsubtype: number;
}

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  private apiService = inject(ApiService);

  transStatusOptions = signal<SelectItem[]>([]);
  transTypeOptions = signal<SelectItem[]>([]);
  transSubTypeOptions = signal<SelectItem[]>([]);
  transVenueOptions = signal<SelectItem[]>([]);

  transConstants = new TransConstants();
  transConstants$ = this.fetchConstants().pipe(
    tap(({ transStatuses, transTypes, transSubTypes, transVenues }) => {
      this.transStatusOptions.set(Object.entries(transStatuses).map(([id, name]) => ({ label: name, value: +id })));
      this.transTypeOptions.set(Object.entries(transTypes).map(([id, name]) => ({ label: name, value: +id })));
      this.transSubTypeOptions.set(Object.entries(transSubTypes).map(([id, subType]) => ({ label: subType.name, value: +id })));
      this.transVenueOptions.set(transVenues.map(value => ({ label: value, value })));
    }),
    shareReplay(1),
  );

  getConstants() {
    return this.transConstants$.pipe(first());
  }

  fetchTransaction(transId: number) {
    return this.apiService.get(httpTransactionItem(transId));
  }

  updateTransaction(transId: number, data: any) {
    return this.apiService.post(httpTransactionItem(transId), data);
  }

  putTransaction(data: any) {
    return this.apiService.put(httpTransaction(), data);
  }

  getReferences(transId: number) {
    return this.apiService.get<{
      source: TransactionRef[];
      destination: TransactionRef[];
      related: TransactionRef[];
    }>(httpTransactionsReferences(transId));
  }

  updateReferences({ fromBucket, toBucket, transactionIds, type }: TransactionReferenceParams) {
    return this.apiService.post(httpTransactionsReferences(fromBucket), {
      destination_bucket: toBucket,
      trans_ids: transactionIds,
      relation_type: type,
    });
  }

  removeReferences({ fromBucket, transactionIds, type }: TransactionReferenceParams) {
    return this.apiService.delete(httpTransactionsReferences(fromBucket), {
      body: {
        trans_ids: transactionIds,
        relation_type: type,
      },
    });
  }

  splitBucket({
    fromBucket,
    transactionIds,
    type,
  }: {
    fromBucket: number;
    transactionIds: number[];
    type: 'source' | 'destination' | 'related';
  }) {
    return this.apiService.put(httpTransactionsSplitBucket(fromBucket), {
      transaction_ids: transactionIds,
      relation_type: type,
    });
  }

  allocateDefault(transId: number, params: { agentId: number; mca_alloc_list: McaAllocationList[] }) {
    return this.apiService.post(httpTransactionsAllocDef(transId), params);
  }

  allocateNonAchPmnt(transId: number, mca_alloc_list: McaAllocationList[]) {
    return this.apiService.post(httpTransactionsAllocNonAchPmnt(transId), { mca_alloc_list });
  }

  getACHRecords(params: { venue: number; startDate: string; endDate: string; debit: boolean }) {
    return this.apiService.get<ACHRecord[]>(httpTransactionsACHSearch(), { params });
  }

  getTransactionEvents(id: number): Observable<BusinessEventModel<any>[]> {
    return this.apiService.get(httpBETransactionsEvents(id));
  }

  getTransactionChangeLog(id: number): Observable<any[]> {
    return this.apiService.get(httpTransactionChangelog(id));
  }

  private fetchConstants(): Observable<TransConstants> {
    return this.apiService.get(httpTransactionsConstants()).pipe(
      tap(response => {
        this.transConstants = new TransConstants();
        this.transConstants.transStatuses = response['transStatuses'];
        this.transConstants.transTypes = response['transTypes'];
        this.transConstants.transSubTypes = response['transSubTypes'];
        this.initAcron();
      }),
    );
  }

  private initAcron(): void {
    Object.keys(this.transConstants.transStatuses).forEach(k => {
      switch (this.transConstants.transStatuses[+k]) {
        case 'Prepared':
          TransStatusAcro.prep = Number(k);
          break;
        case 'Scheduled':
          TransStatusAcro.sched = Number(k);
          break;
        case 'Sent':
          TransStatusAcro.sent = Number(k);
          break;
        case 'Hold':
          TransStatusAcro.hold = Number(k);
          break;
        case 'Suspended':
          TransStatusAcro.suspended = Number(k);
          break;
        case 'ReScheduled':
          TransStatusAcro.reSched = Number(k);
          break;
        case 'Failed':
          TransStatusAcro.failed = Number(k);
          break;
        case 'Canceled':
          TransStatusAcro.canceled = Number(k);
          break;
        case 'Returned':
          TransStatusAcro.returned = Number(k);
          break;
        case 'Pending':
          TransStatusAcro.pending = Number(k);
          break;
        case 'Manual':
          TransStatusAcro.manual = Number(k);
          break;
        case 'Allocated':
          TransStatusAcro.allocated = Number(k);
          break;
        case 'Processed':
          TransStatusAcro.processed = Number(k);
          break;
      }
    });

    transDefenitions.transActiveStatus = [
      TransStatusAcro.sent,
      TransStatusAcro.processed,
      TransStatusAcro.pending,
      TransStatusAcro.allocated,
      TransStatusAcro.returned,
    ];
    transDefenitions.transPaymentActiveStatus = [TransStatusAcro.processed, TransStatusAcro.allocated];

    Object.keys(this.transConstants.transTypes).forEach(k => {
      switch (this.transConstants.transTypes[+k]) {
        case 'Payment':
          TransTypeAcro.payment = Number(k);
          break;
        case 'Fee':
          TransTypeAcro.fee = Number(k);
          break;
        case 'Contract Fee':
          TransTypeAcro.cfee = Number(k);
          break;
        case 'Deposit To Merchant':
          TransTypeAcro.dtm = Number(k);
          break;
        case 'Deposit To Outstanding':
          TransTypeAcro.dto = Number(k);
          break;
        case 'Deposit Consolidate':
          TransTypeAcro.dcon = Number(k);
          break;
        case 'Deposit':
          TransTypeAcro.deposit = Number(k);
          break;
        case 'Withdrawal':
          TransTypeAcro.withdrawal = Number(k);
          break;
        case 'Commission':
          TransTypeAcro.commission = Number(k);
          break;
        case 'Mgmt Fee':
          TransTypeAcro.feeMgmt = Number(k);
          break;
        case 'Settlement':
          TransTypeAcro.settlement = Number(k);
          break;
        case 'Refund':
          TransTypeAcro.refund = Number(k);
          break;
        case 'TTI':
          TransTypeAcro.tti = Number(k);
          break;
        case 'TFI':
          TransTypeAcro.tfi = Number(k);
          break;
        case 'Default':
          TransTypeAcro.dflt = Number(k);
          break;
        case 'Discount':
          TransTypeAcro.discount = Number(k);
          break;
        case 'Recall':
          TransTypeAcro.recall = Number(k);
          break;
        case 'Synd Fee':
          TransTypeAcro.syndFee = Number(k);
          break;
        case 'Non ACH Pmnt':
          TransTypeAcro.nonAchPayment = Number(k);
          break;
        case 'Fee Waiver':
          TransTypeAcro.feeWaiver = Number(k);
          break;
        case 'Placeholder':
          TransTypeAcro.placeholder = Number(k);
          break;
      }
    });

    Object.keys(this.transConstants.transSubTypes).forEach(k => {
      switch (this.transConstants.transSubTypes[+k].name) {
        case 'ReScheduled':
          TransSubTypeAcro.reSched = Number(k);
          break;
        case 'Month Admin Fee':
          TransSubTypeAcro.admin_fee = Number(k);
          break;
        case 'Failed Payment':
          TransSubTypeAcro.fp = Number(k);
          break;
        case 'Failed Fee Payment':
          TransSubTypeAcro.ffp = Number(k);
          break;
        case 'NSF Fee':
          TransSubTypeAcro.nsf_fee = Number(k);
          break;
        case 'Commission':
          TransSubTypeAcro.commission = Number(k);
          break;
        case 'Attorney Fee':
          TransSubTypeAcro.attorneyFee = Number(k);
          break;
        case 'Loan':
          TransSubTypeAcro.loan = Number(k);
          break;
        case 'Interest':
          TransSubTypeAcro.interest = Number(k);
          break;
        case 'Principal':
          TransSubTypeAcro.principal = Number(k);
          break;
        case 'Invoice':
          TransSubTypeAcro.invoice = Number(k);
          break;
        case 'Acct Change':
          TransSubTypeAcro.acct_change_fee = Number(k);
          break;
        case 'Termination Fee':
          TransSubTypeAcro.termination_fee = Number(k);
          break;
      }
    });
  }

  releaseBulk(tids: number[], generateInvoice: boolean, startDate?: Date, endDate?: Date, overrideDate?: Date, samedayach?: boolean) {
    const body: any = { tids, generateInvoice, samedayach };
    if (overrideDate) {
      body.overrideDate = dateAsYYYYMMDD(overrideDate);
    }
    if (generateInvoice) {
      body.startDate = dateAsYYYYMMDD(startDate);
      body.endDate = dateAsYYYYMMDD(endDate);
    }
    return this.apiService.post(httpTransactionsReleaseBulk(), body);
  }

  commissionActivateCandidates() {
    return this.apiService.get(httpCommissionActivateCandidates());
  }

  receivablesAch(transDate: Date, days: number) {
    return this.apiService.get<{
      [date: string]: ReceivableAch[];
    }>(receivablesAch(dateAsYYYYMMDD(transDate) as string, days));
  }

  getCommissionInvoiceSummary(params: { userid: number | null; startDate: string; endDate: string }) {
    return this.apiService.get<CommissionInvoiceAction[]>(httpCommissionInvoiceSummary(), { params });
  }

  createCommissionInvoice(params: CreateCommissionInvoice): Observable<any> {
    return this.apiService.post(httpCreateCommissionInvoice(), params);
  }

  getTransactionAggregation(params: AggregationFilters): Observable<AggregationAction[]> {
    return this.apiService.post(httpTransactionAggregation(), {
      ...params,
      start_date: dateAsYYYYMMDD(params.start_date),
      end_date: dateAsYYYYMMDD(params.end_date),
    });
  }

  mergeSelectedTransactions(buckets_list: number[]) {
    return this.apiService.put(httpTransactionsMerge(), { buckets_list });
  }

  holdTransaction(id: number) {
    return this.apiService.post(httpTransactionHold(id));
  }

  voidTransaction(id: number) {
    return this.apiService.post(httpTransactionVoid(id));
  }

  assign(
    transId: number,
    data: {
      ref_name: TransAssignmentType | null;
      ref_id: number | null;
    },
  ) {
    return this.apiService.put(httpTransactionAssign(transId), data);
  }

  unassign(transId: number) {
    return this.apiService.put(httpTransactionUnassign(transId));
  }

  getCollectorUnallocatedList(transId: number) {
    return this.apiService.get<CollectorPayment[]>(httpTransactionCollectorUnallocated(transId));
  }

  allocateCollectorPayments(
    transId: number,
    data: {
      mcaid: number;
      amount: number;
      trans_id?: number;
    }[],
  ) {
    return this.apiService.post(httpTransactionCollectorAllocate(transId), data);
  }

  getTransactionTransferAccount(transId: number) {
    return this.apiService.get<TransactionTransferAccount[]>(httpTransactionTransferAccount(transId));
  }

  activateBPWithdrawalTransfer(transId: number) {
    return this.apiService.post(httpActivateBPWithdrawalTransfer(transId));
  }

  getDefaultFees(params: { venue: number; startDate: string; endDate: string }) {
    return this.apiService.get<
      {
        userid: number;
        amount: number;
      }[]
    >(httpDefaultFees(), { params });
  }

  activateWithdrawalTransaction(transactionid: number) {
    return this.apiService.post(httpActivateWithdrawalTransaction(), { transactionid });
  }
}
